.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

:root {
  --bg-color: #e5e7eb;
  --button-bg-color: #1a202c;
  --button-text-color: #ffffff;
  --border-color: #000000;
}

.Robote_bold {
  font-family: "Robote_bold";
}
body{
  overflow-x: hidden;
}
.Robote {
  font-family: "Robote";
}
.line {
  height: 20px;
  /* Increased height for better visibility of the curve */
  background-color: black;
  /* margin: 10px 0; */
  width: 200px;
  /* Uniform width for all lines */
  /* padding: 10px; */
}


.bent {
  width: 95%;
  height: 150%;
  border: solid 5px;
  border-color: transparent transparent transparent;
  border-radius: 100% 100% 0 0;
  margin-left: 45px;
  margin-top: -18px;
}

.bent2 {
  width: 95%;
  height: 150%;
  border: solid 5px;
  border-color: transparent transparent transparent;
  border-radius: 100% 100% 0 0;
  margin-left: -28px;
  margin-top: -18px;
}

@keyframes shake {
  0%, 100% {
    transform: translateY(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateY(-3px);
    
  }
  20%, 40%, 60%, 80% {
    transform: translateY(3px);
  }
}

.animate-shake {
  animation: shake 10s ease-in-out infinite;
}


@keyframes shakeX {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(0px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(1px);
  }
}

.animate-shakeX {
  animation: shakeX 10s ease-in-out infinite;
}
@keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-scale {
  animation: scale 1s ease-in-out infinite;
}


@keyframes ring {
  0% { transform: rotate(0); }
  15% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  45% { transform: rotate(5deg); }
  60% { transform: rotate(-5deg); }
  75% { transform: rotate(5deg); }
  100% { transform: rotate(0); }
}
.animate-ring {
  animation: ring 1s ease-in-out infinite;
}

.action-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white; /* Adjust if you have a specific shade */
  border-radius: 20px; /* This gives the rounded effect */
  padding: 8px 18px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: Adds shadow for depth */
  margin-left: 3rem;

}

.action-icon {
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
  margin-right: 4px; /* Gives some space between the icon and the text */
}

.action-text {
  font-size: 14px; /* Adjust the size as needed */
  color: black; /* Adjust if you have a specific text color */
}


.Brittany {
  font-family: "Brittany";
}

.skew-container {
  transform: skewX(25deg);
}

.skew-containers {
  transform: skewX(-25deg);
}

.svg {
  transform: rotate(180deg);
}

.curved {
  position: relative;
  background: #2c3e50;
  height: 40vh;
  border-bottom-left-radius: 50% 25%;
  border-bottom-right-radius: 50% 25%;
}

@keyframes slide-from-right {
  0% {
    transform: translateX(200%);
    opacity: 0;
  }

  150% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-from-right {
  animation: slide-from-right 1s ease-out forwards;
  /* Adjusted to 0.1s */
}