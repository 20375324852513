@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Robote_bold";
  src: url("Fonts/Fontspring-DEMO-singel-book.ttf") format("truetype");
}

@font-face {
  font-family: "Robote";
  src: url("Fonts/Fontspring-DEMO-singel-book.ttf") format("truetype");
}

@font-face {
  font-family: "Robote_thin";
  src: url("Fonts/Fontspring-DEMO-singel-book.ttf") format("truetype");
}
@font-face {
  font-family: "Brittany";
  src: url("Fonts/BrittanySignature.ttf") format("truetype");
}

@font-face {
  font-family: "myanmar";
  src: url("./Fonts/Myanmar1.ttf") format("truetype");
}

@font-face {
  font-family: "mvboli";
  src: url("./Fonts/MVBOLI.TTF") format("truetype");
}

@font-face {
  font-family: "PMINGLIU";
  src: url("./Fonts/PMINGLIU.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./Fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./Fonts/Poppins-Light.ttf") format("truetype");
}


@font-face {
  font-family: "Rubik-Light";
  src: url("./Fonts/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MTCORSVA";
  src: url("./Fonts/MTCORSVA.TTF") format("truetype");
}

@font-face {
  font-family: "REFSAN";
  src: url("./Fonts/REFSAN.TTF") format("truetype");
}


@font-face {
  font-family: "Acumin-BdItPro";
  src: url("./Fonts/Acumin-BdItPro.otf") format("truetype");
}

.action-item {
  display: flex;
  align-items: center;
  background-color: #FFF; /* Or any color you prefer */
  border-radius: 22px; /* Adjust for rounded corners */
  padding: 8px 16px; /* Adjust padding to fit content */
}

.action-icon {
  /* Adjust the size as needed */
  width: 24px;
  height: 24px;
 
}

.action-text {
  /* Adjust the font size as needed */
  font-size: 14px;
  color: #000; /* Or any color you prefer */
}
